<template>
  <div>
    <heder :items="items" :user="user"></heder>
    <router-View class="main-view" name="Mainview" :user="user"></router-View>
    <footernya :items="items"></footernya>
  </div>
</template>

<script>
import footernya from "./component/Footer";
import heder from "./component/Header.vue";
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      drawer: null,
      menuu: [
        { title: "Home", icon: "mdi-home", route: "/" },
        {
          title: "Transaksi",
          icon: "mdi-cash-multiple",
          route: "/transaksi",
        },
        { title: "Toke", icon: "mdi-account-box", route: "/toke" },
        { title: "Pinjaman", icon: "mdi-account-box", route: "/pinjaman" },
        { title: "Rekap Pinjaman", icon: "mdi-pen", route: "/rekap" },
        {
          title: "Tonase",
          icon: "mdi-file-table-box-outline",
          route: "/tonase",
        },
        {
          title: "Tutup Buku",
          icon: "mdi-poll",
          route: "/tutupbuku",
        },
        {
          title: "Ram",
          icon: "mdi-scale",
          route: "/ram",
        }, //indexram
      ],
      items: [],
      user: {},
      token: localStorage.getItem("token"),
      level: [
        {
          level: "kasir",
          menu: ["Home", "Transaksi", "Tonase", "Toke"],
        },
        {
          level: "super_admin",
          menu: [
            "Home",
            "Transaksi",
            "Tonase",
            "Toke",
            "Pinjaman",
            "Rekap Pinjaman",
            "Tutup Buku",
            "Ram",
          ],
        },
        {
          level: "ramp",
          menu: ["Home", "Ramp"],
        },
        {
          level: "kasir_limit",
          menu: ["Home", "Tonase"],
        },
      ],
    };
  },
  components: {
    footernya,
    heder,
  },
  created() {
    axios.defaults.headers.common["Authorization"] = `Bearer ${this.token}`;
    this.getUser();
  },
  methods: {
    getUser() {
      axios
        .get("/user/aktif")
        .then((response) => {
          this.user = response.data;
          let level = this.level.find((x) => this.user.user_level == x.level);
          console.log(this.user);
          this.items = this.menuu.filter((x) => {
            return level.menu.indexOf(x.title) > -1;
          });
          const a = this.items.findIndex((x) => x.title == "Transaksi");
          if (a > 0) {
            if (this.user.user_level == "kasir") {
              this.items[a].route = `/transaksi/${this.user.akunkasir}`;
            }
          }
          console.log(this.items);
          console.log(this.user);
        })
        .catch((err) => {
          console.log(err);
          this.$noty.error("Terjadi Error, periksa jaringan");
        });
    },
  },
};
</script>
