<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="tutup()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Print</v-toolbar-title><v-spacer></v-spacer
        ><download-excel
          class="mx-2"
          :data="tabel"
          :fields="fieldExcell"
          worksheet="My Worksheet"
          :name="filename"
        >
          <v-btn dark color="blue"><v-icon>mdi-table-large</v-icon></v-btn>
        </download-excel>
        <v-btn dark color="pink" @click="print"
          ><v-icon>mdi-printer</v-icon></v-btn
        >
      </v-toolbar>
      <v-card-text> </v-card-text>
      <div id="printx1" class="printarea overflow-auto">
        <div>
          <table class="rekap">
            <tbody>
              <tr>
                <td>Nama Kasir</td>
                <td>: {{ admin.nama }}</td>
              </tr>
              <tr>
                <td>Tanggal di Print</td>
                <td>
                  : {{ tanggalPrint[0] | formatDate }} -
                  {{ tanggalPrint[1] | formatDate }}
                </td>
              </tr>
            </tbody>
          </table>
          <td></td>
        </div>
        <div class="container">
          <div v-if="modalA.length >> 0">
            <table class="lis">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">Modal Masuk</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in modalA" :key="item.id" class="ma-2">
                  <td>{{ item.tanggal | formatDate }}</td>
                  <td>{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr class="total">
                  <td class="text-left">Total</td>
                  <td></td>
                  <td>
                    {{ sModalM | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="kolom"></div>
        </div>
        <div class="container">
          <div><span>Tonase</span></div>
          <table class="tonase">
            <thead>
              <tr>
                <th class="text-center">Tanggal</th>
                <th class="text-center">Pabrik</th>
                <th class="text-center">Nama</th>
                <th class="text-center">Plat</th>
                <th class="text-center">Tonase</th>
                <th class="text-center">Harga</th>
                <th class="text-center">Cair</th>
              </tr>
            </thead>
            <tbody v-for="items in grupTonase" :key="items.id">
              <tr v-for="item in items.data" :key="item.id">
                <td>{{ item.tanggaldo | formatDate }}</td>
                <td>{{ item.do.pabrik.pabrik }}</td>
                <td>{{ item["nama supir"] }}</td>
                <td>{{ item.plat }}</td>
                <td>{{ item.tonase | angka }}</td>
                <td>
                  {{ (parseInt(item.harga) + parseFloat(item.th)) | rupiah }}
                </td>
                <td>
                  {{
                    (item.tonase * (parseInt(item.harga) + parseFloat(item.th)))
                      | rupiah
                  }}
                </td>
              </tr>
              <tr>
                <td colspan="4" class="text-left">Total</td>
                <td>
                  {{ items.tonase | angka }}
                </td>
                <td></td>
                <td>
                  {{ items.cair | rupiah }}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr class="total">
                <td colspan="4" class="text-left">Total</td>
                <td>
                  {{ sTonase | angka }}
                </td>
                <td></td>
                <td>
                  {{ sCair | rupiah }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="container">
          <div class="kolom" v-if="pinjamanA.length >> 0">
            <table class="lis">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">Pengeluaran Pinjaman</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="isiPinjam.PS.length > 0">
                  <td colspan="2" style="font-weight: bold">Pinjaman Modal</td>
                </tr>
                <tr v-for="item in isiPinjam.PS" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr v-if="isiPinjam.PT.length > 0">
                  <td colspan="2" style="font-weight: bold">Pinjaman Tetap</td>
                </tr>
                <tr v-for="item in isiPinjam.PT" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr v-if="isiPinjam.lain.length > 0">
                  <td colspan="2" style="font-weight: bold">
                    Pengeluaran Lainnya
                  </td>
                </tr>
                <tr v-for="item in isiPinjam.lain" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr class="total">
                  <td class="text-left">Total</td>
                  <td>
                    {{ sPinjaman | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="kolom ml-4 mb-6" v-if="pinjamanB.length > 0">
            <table class="lis">
              <thead>
                <tr>
                  <th class="text-left" colspan="2">Modal Dari Angsuran</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="isiAngsur.PS.length > 0">
                  <td colspan="2" style="font-weight: bold">Potong Modal</td>
                </tr>
                <tr v-for="item in isiAngsur.PS" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr v-if="isiAngsur.PT.length > 0">
                  <td colspan="2" style="font-weight: bold">Potong Tetap</td>
                </tr>
                <tr v-for="item in isiAngsur.PT" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr v-if="isiAngsur.lain.length > 0">
                  <td colspan="2" style="font-weight: bold">Potongan Lainya</td>
                </tr>
                <tr v-for="item in isiAngsur.lain" :key="item.id" class="ma-2">
                  <td class="text-left">{{ item.uraian }}</td>
                  <td>{{ item.hitung | rupiah }}</td>
                </tr>
                <tr class="total">
                  <td class="text-left">Total</td>
                  <td>
                    {{ sAngsuran | rupiah }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <br />
        <div class="container mt-4">
          <table class="rekap">
            <tbody>
              <tr>
                <td>Total Modal</td>
                <td>: {{ sModalM | rupiah }}</td>
              </tr>
              <tr>
                <td>Pengeluaran Pencairan</td>
                <td>: {{ sCair | rupiah }}</td>
              </tr>
              <tr>
                <td>Pengeluaran Pinjaman dll</td>
                <td>: {{ sPinjaman | rupiah }}</td>
              </tr>
              <tr class="total">
                <td>Sisa Saldo</td>
                <td>: {{ tTotal | rupiah }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>
<style>
@import "@/assets/aplikasi/riz/transaksiPortrait.css";
@media print {
  body *:not(#printx1):not(#printx1 *) {
    visibility: hidden;
  }
  #printx1 {
    position: absolute;
    top: 0;
    left: 0;
  }
}
</style>
<script>
import axios from "axios";
import moment from "moment";
export default {
  components: {},
  props: ["pemicu", "tanggalPrint", "admin"],
  computed: {
    dialog: {
      get() {
        return this.pemicu;
      },
      set() {
        this.$emit("tutup");
      },
    },
    filename() {
      return `TransaksiKasir -${moment().format("DD-MM-YY hh")}`;
    },
  },
  data() {
    return {
      tonase: [],
      saldoKemarin: 0,
      pinjaman: [],
      pinjamanA: [],
      pinjamanB: [],
      modal: [],
      modalA: [],
      modalB: [],
      loading: false,
      sPinjaman: 0,
      sAngsuran: 0,
      sTonase: 0,
      sCair: 0,
      sModalM: 0,
      sModalK: 0,
      tMasuk: 0,
      tKeluar: 0,
      tTotal: 0,
      isiAngsur: [],
      isiPinjam: [],
      grupTonase: [],
      fieldExcell: {
        A: "a1",
        B: "a2",
        C: "a3",
        D: "a4",
        E: "a5",
        F: "a6",
        G: "a7",
        H: "a8",
        I: "a9",
        J: "a10",
        K: "a11",
        L: "a12",
        M: "a13",
        N: "a14",
      },
      tabel: [],
    };
  },
  watch: {
    pemicu() {
      if (this.pemicu) {
        this.inisialisasi();
      }
    },
  },
  methods: {
    inisialisasi() {
      console.log("dibuka");
      console.log(this.tanggalPrint);
      this.loading = true;
      const uri =
        "/transaksi/print/" +
        this.admin.id +
        "/" +
        this.tanggalPrint[0] +
        "/" +
        this.tanggalPrint[1] +
        "";
      axios.get(uri).then((response) => {
        console.log(response.data);
        this.tonase = response.data.tonase;
        this.grupTonase = this.grupkan(this.tonase);
        this.pTonase();
        this.saldoKemarin = response.data.saldoKemarin;
        this.pinjamanA = this.rubah(response.data.pinjam);
        this.pinjamanB = this.rubah(response.data.angsur);
        this.modalA = [{ uraian: "SALDO AWAL", hitung: this.saldoKemarin }];
        this.modalA = this.modalA.concat(this.rubah(response.data.modal));
        let { newArrayA, newArrayB } = this.hapusNilaiSama(
          this.pinjamanA,
          this.pinjamanB,
          "toke",
          "hitung"
        );
        this.pinjamanA = newArrayA;
        this.pinjamanB = newArrayB;
        this.pModal();
        this.Saldo();
        this.tabelExcel();
        this.loading = false;
        this.isiAngsur = {
          PS: this.pinjamanB.filter((x) => x.uraian.startsWith("PS-")),
          PT: this.pinjamanB.filter((x) => x.uraian.startsWith("PT-")),
          lain: this.pinjamanB.filter(
            (x) => !x.uraian.startsWith("PS-") && !x.uraian.startsWith("PT-")
          ),
        };
        this.isiPinjam = {
          PS: this.pinjamanA.filter((x) => x.uraian.startsWith("PS-")),
          PT: this.pinjamanA.filter((x) => x.uraian.startsWith("PT-")),
          lain: this.pinjamanA.filter(
            (x) => !x.uraian.startsWith("PS-") && !x.uraian.startsWith("PT-")
          ),
        };
      });
    },
    rubah(x) {
      return Object.keys(x).map((a) => x[a]);
    },
    hapusNilaiSama(arr1, arr2, key1, key2) {
      // Buat set dari elemen-elemen yang memiliki properti yang sama
      let commonElements = new Set(
        arr1
          .filter((obj1) =>
            arr2.some(
              (obj2) => obj1[key1] === obj2[key1] && obj1[key2] === obj2[key2]
            )
          )
          .map((obj) => JSON.stringify({ key1: obj[key1], key2: obj[key2] }))
      );
      console.log(commonElements);
      // Filter array untuk menghapus elemen yang ada di set commonElements
      let newArrayA = arr1.filter(
        (obj) =>
          !commonElements.has(
            JSON.stringify({ key1: obj[key1], key2: obj[key2] })
          )
      );
      let newArrayB = arr2.filter(
        (obj) =>
          !commonElements.has(
            JSON.stringify({ key1: obj[key1], key2: obj[key2] })
          )
      );

      return { newArrayA, newArrayB };
    },
    tabelExcel() {
      this.tonase.map((x) => {
        let item = {};
        item.a1 = "Tonase";
        item.a2 = x.do.pabrik.pabrik;
        let i = 3;
        for (const key in x) {
          const n = "a" + i;
          item[n] = x[key];
          i++;
        }
        this.tabel.push(item);
      });
      this.pinjaman.map((x) => {
        let item = {};
        item.a1 = "Pinjaman";
        item.a2 = x.toke.nama;
        item.a3 = x.toke.golongan;
        let i = 4;
        for (const key in x) {
          const n = "a" + i;
          item[n] = x[key];
          i++;
        }
        this.tabel.push(item);
      });
    },
    grupkan(array) {
      if (array.length >> 0) {
        var hasil = [];
        let c = array.reduce(function (chace, item) {
          const properti = item.do.pabrik.id;
          if (chace.some((el) => el.idpabrik === properti)) {
            var terpilih = chace.find(function (x) {
              return x.idpabrik === properti;
            });
            terpilih.tonase += parseInt(item.tonase);
            terpilih.cair +=
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th));
            terpilih.data.push(item);
            return [...chace];
          }
          chace.push({
            tonase: parseInt(item.tonase),
            cair:
              parseInt(item.tonase) *
              (parseFloat(item.harga) + parseFloat(item.th)),
            pabrik: item.do.pabrik.pabrik,
            idpabrik: item.do.pabrik.id,
            data: [item],
          });
          return [...chace];
        }, []);
        c.forEach(function (item) {
          hasil.push(item);
        });
        hasil.sort(function (a, b) {
          return a.idpabrik - b.idpabrik;
        });
        console.log(hasil);
        return hasil;
      }
      return array;
    },
    tutup() {
      this.$emit("tutup");
    },
    pTonase() {
      this.tonase = this.tonase.map(function (x) {
        return {
          ...x,
          cair: parseInt(x.tonase) * (parseInt(x.harga) + parseFloat(x.th)),
        };
      });
    },
    pModal() {},
    Saldo() {
      this.sPinjaman = this.sumPer(this.pinjamanA, "hitung");
      this.sAngsuran = this.sumPer(this.pinjamanB, "hitung");
      this.modalA = this.modalA.concat([
        {
          uraian: "Total Angsuran",
          hitung: this.sAngsuran,
        },
      ]);
      console.log(this.modalA);
      this.sModalM = this.sumPer(this.modalA, "hitung");
      this.sTonase = this.sumPer(this.tonase, "tonase");
      this.sCair = this.sumPer(this.tonase, "cair");
      this.tTotal = this.sModalM - this.sCair - this.sPinjaman;
    },
    sumPer(item, prop) {
      return item.reduce(function (a, b) {
        return a + (parseInt(b[prop]) || 0);
      }, 0);
    },
    print() {
      this.$htmlToPaper("printx1", {
        styles: [
          "https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css",
          "/aplikasi/riz/app.css",
          "/aplikasi/riz/transaksiPortrait.css",
        ],
      });
    },
  },
};
</script>
